import Layout from '@/layout';
var breedRouter = {
  path: '/teaBreed',
  component: Layout,
  redirect: '/teaBreed/TeaBreedManage',
  name: 'TeaBreed',
  meta: {
    title: '油茶品种',
    icon: 'clipboard'
  },
  children: [{
    path: 'cate/list',
    name: 'TeaBreedCateManage',
    component: function component() {
      return import('@/views/teabreed/cate/index');
    },
    meta: {
      title: '品种分类',
      icon: 'clipboard'
    }
  }, {
    path: 'list/list',
    name: 'TeaBreedManage',
    component: function component() {
      return import('@/views/teabreed/view/index');
    },
    meta: {
      title: '品种列表',
      icon: 'clipboard'
    }
  }]
};
export default breedRouter;