var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-menu",
    {
      attrs: {
        "default-active": _vm.activeMenu,
        "text-color": "#000000",
        "active-text-color": "#3989fa",
        mode: "horizontal",
      },
      on: { select: _vm.handleSelect },
    },
    _vm._l(_vm.topMenus, function (item, index) {
      return _c("el-menu-item", { key: index, attrs: { index: item.url } }, [
        _c("span", { attrs: { slot: "title" }, slot: "title" }, [
          _vm._v(_vm._s(item.name)),
        ]),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }