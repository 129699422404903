import Layout from '@/layout';
var fineRouter = {
  path: '/fineView',
  component: Layout,
  redirect: '/fineView/FineViewManager',
  name: 'FineView',
  meta: {
    title: '乡村美景',
    icon: 'clipboard'
  },
  children: [{
    path: 'cate/list',
    name: 'FineViewCateManager',
    component: function component() {
      return import('@/views/fineView/cate/index');
    },
    meta: {
      title: '乡村美景分类',
      icon: 'clipboard'
    }
  }, {
    path: 'view/list',
    name: 'FineViewManager',
    component: function component() {
      return import('@/views/fineView/view/index');
    },
    meta: {
      title: '乡村美景列表',
      icon: 'clipboard'
    }
  }, {
    path: 'view/addView/:id?/:isDisabled?',
    component: function component() {
      return import('@/views/fineView/view/addView/index');
    },
    name: 'ViewAdd',
    meta: {
      title: '乡村美景添加',
      noCache: true,
      activeMenu: "/fineView/view/list"
    },
    hidden: true
  }, {
    path: 'tag/list',
    name: 'fineViewTagManager',
    component: function component() {
      return import('@/views/fineView/tag/index');
    },
    meta: {
      title: '乡村美景标签',
      icon: 'clipboard'
    }
  }]
};
export default fineRouter;