import Layout from '@/layout';
var volunteerRouter = {
  path: '/volunteer',
  component: Layout,
  redirect: '/volunteer/index',
  name: 'volunteer',
  meta: {
    title: '志愿者列表',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/volunteer/list');
    },
    name: 'VolunteerIndex',
    meta: {
      title: '志愿者列表',
      icon: ''
    }
  }]
};
export default volunteerRouter;