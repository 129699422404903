import Layout from '@/layout';
var activityRouter = {
  path: '/activity',
  component: Layout,
  redirect: '/activity/index',
  name: 'activity',
  meta: {
    title: '活动管理',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/activity/list');
    },
    name: 'Activity',
    meta: {
      title: '活动列表',
      icon: ''
    }
  }, {
    path: 'addActivity/:id?/:isDisabled?',
    component: function component() {
      return import('@/views/activity/edit');
    },
    name: 'ActivityAdd',
    meta: {
      title: '活动添加',
      noCache: true,
      activeMenu: "/activity/index"
    },
    hidden: true
  }, {
    path: 'log/index',
    component: function component() {
      return import('@/views/activity/log/list');
    },
    name: 'ActivityLog',
    meta: {
      title: '活动记录列表',
      icon: ''
    }
  }]
};
export default activityRouter;