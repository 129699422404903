import Layout from '@/layout';
var screenRouter = {
  path: '/screen',
  component: Layout,
  redirect: '/screen/index',
  name: 'screen',
  meta: {
    title: '大屏管理',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/screen/index');
    },
    name: 'ScreenIndex',
    meta: {
      title: '油茶数据',
      icon: ''
    }
  }]
};
export default screenRouter;