import Layout from '@/layout';
var shopRouter = {
  path: '/shop',
  component: Layout,
  redirect: '/shop/shopManager',
  name: 'shop',
  meta: {
    title: '店铺',
    icon: 'clipboard'
  },
  children: [{
    path: 'cate/list',
    name: 'shopCateManager',
    component: function component() {
      return import('@/views/shop/shopCate/index');
    },
    meta: {
      title: '店铺分类',
      icon: 'clipboard'
    }
  }, {
    path: 'shop/list',
    name: 'shopManager',
    component: function component() {
      return import('@/views/shop/shop/index');
    },
    meta: {
      title: '店铺列表',
      icon: 'clipboard'
    }
  }, {
    path: 'shop/addShop/:id?/:isDisabled?',
    component: function component() {
      return import('@/views/shop/shop/addShop/index');
    },
    name: 'shopProductAdd',
    meta: {
      title: '店铺添加',
      noCache: true,
      activeMenu: "/shop/shop/list"
    },
    hidden: true
  }, {
    path: 'tag/list',
    name: 'tagManager',
    component: function component() {
      return import('@/views/shop/tag/index');
    },
    meta: {
      title: '店铺标签',
      icon: 'clipboard'
    }
  }, {
    path: 'product/list',
    name: 'shopProductManager',
    component: function component() {
      return import('@/views/shop/shopProduct/index');
    },
    meta: {
      title: '店铺商品列表',
      icon: 'clipboard'
    }
  }, {
    path: 'product/addShopProduct/:id?/:isDisabled?',
    component: function component() {
      return import('@/views/shop/shopProduct/addShopProduct/index');
    },
    name: 'shopProductAdd',
    meta: {
      title: '店铺商品添加',
      noCache: true,
      activeMenu: "/shop/product/list"
    },
    hidden: true
  }, {
    path: 'order/list',
    name: 'shopOrderManager',
    component: function component() {
      return import('@/views/shop/shopOrder/index');
    },
    meta: {
      title: '订单列表',
      icon: 'clipboard'
    }
  }]
};
export default shopRouter;