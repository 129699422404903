import Layout from '@/layout';
var pageRouter = {
  path: '/page',
  component: Layout,
  redirect: '/page/index',
  name: 'Page',
  meta: {
    title: '页面设置',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/page/index');
    },
    name: 'PageIndex',
    meta: {
      title: '页面管理',
      icon: ''
    }
  }]
};
export default pageRouter;