import Layout from '@/layout';
var contentRouter = {
  path: '/content',
  component: Layout,
  redirect: '/content/articleManager',
  name: 'content',
  meta: {
    title: '内容',
    icon: 'clipboard'
  },
  children: [{
    path: 'articleManager',
    name: 'articleManager',
    component: function component() {
      return import('@/views/content/article/list');
    },
    meta: {
      title: '文章管理',
      icon: 'clipboard'
    }
  }, {
    path: 'articleCreat/:id?',
    name: 'articleCreat',
    component: function component() {
      return import('@/views/content/article/edit');
    },
    meta: {
      title: '添加文章',
      noCache: true,
      activeMenu: "/content/articleManager"
    }
  }, {
    path: 'classifManager',
    name: 'classifManager',
    component: function component() {
      return import('@/views/content/articleclass/list');
    },
    meta: {
      title: '文章分类',
      icon: 'clipboard'
    }
  }, {
    path: 'skill/cate',
    name: 'SkillCate',
    component: function component() {
      return import('@/views/content/skill/cate');
    },
    meta: {
      title: '遗产技艺分类',
      icon: 'clipboard'
    }
  }, {
    path: 'skill/list',
    name: 'SkillList',
    component: function component() {
      return import('@/views/content/skill/list');
    },
    meta: {
      title: '遗产技艺列表',
      icon: 'clipboard'
    }
  }, {
    path: 'cultural/cate',
    name: 'CulturalCate',
    component: function component() {
      return import('@/views/content/cultural/cate');
    },
    meta: {
      title: '文化传承分类',
      icon: 'clipboard'
    }
  }, {
    path: 'cultural/list',
    name: 'CulturalList',
    component: function component() {
      return import('@/views/content/cultural/list');
    },
    meta: {
      title: '文化传承列表',
      icon: 'clipboard'
    }
  }, {
    path: 'circle/list',
    name: 'CircleList',
    component: function component() {
      return import('@/views/content/circle/list');
    },
    meta: {
      title: '旅游圈子列表',
      icon: 'clipboard'
    }
  }]
};
export default contentRouter;