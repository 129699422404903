//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { productLstApi } from "@/api/store";
import * as articleApi from "@/api/article";
export default {
  props: {
    'visible': false
  },
  data: function data() {
    return {
      tabPosition: 'left',
      result: false,
      data: [{
        label: '文章页面',
        id: -2,
        children: [{
          label: '文章',
          id: 5
        }]
      }, {
        label: '自定义',
        id: -3,
        children: [{
          label: '自定义链接',
          id: 6
        }]
      }],
      clickId: 1,
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      model1: null,
      tableFrom: {
        page: 1,
        limit: 20,
        cateId: '',
        keywords: '',
        type: '1',
        isCalendar: ''
      },
      listLoading: true,
      tableData: {
        data: [],
        total: 0
      },
      productId: null,
      constants: this.$constants,
      listPram: {
        keywords: null,
        cid: null,
        page: 1,
        limit: this.$constants.page.limit[0]
      },
      listData: {
        list: [],
        total: 0
      },
      zdyUrl: null
    };
  },
  methods: {
    handleNodeClick: function handleNodeClick(data) {
      if (data.id > 0) {
        this.clickId = data.id;
      }
      this.productId = null;
      this.model1 = null;
      if (data.id === 4) {
        this.getList();
      }
      if (data.id === 5) {
        this.handlerGetListData(this.listPram);
      }
    },
    seachList: function seachList() {
      this.tableFrom.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      productLstApi(this.tableFrom).then(function (res) {
        _this.tableData.data = res.list;
        _this.tableData.total = res.total;
        _this.listLoading = false;
      }).catch(function (res) {
        _this.listLoading = false;
        _this.$message.error(res.message);
      });
    },
    pageChange: function pageChange(page) {
      this.tableFrom.page = page;
      this.getList();
    },
    handleSizeChange: function handleSizeChange(val) {
      this.tableFrom.limit = val;
      this.getList();
    },
    handerSearch: function handerSearch() {
      this.listPram.page = 1;
      this.handlerGetListData(this.listPram);
    },
    handlerGetListData: function handlerGetListData(pram) {
      var _this2 = this;
      this.listLoading = true;
      articleApi.ListArticle(pram).then(function (data) {
        _this2.listData = data;
        _this2.listLoading = false;
      });
    },
    handleArticleSizeChange: function handleArticleSizeChange(val) {
      this.listPram.limit = val;
      this.handlerGetListData(this.listPram);
    },
    handleArticleCurrentChange: function handleArticleCurrentChange(val) {
      this.listPram.page = val;
      this.handlerGetListData(this.listPram);
    },
    handleSumbit: function handleSumbit() {
      //如果clickID 1 或者 2 的时候
      if (this.clickId === 1 || this.clickId === 2) {
        this.$emit('handleSumbit', this.model1);
        return;
      }
      //如果clickID 为4 ,则商品详情
      if (this.clickId === 4) {
        if (!this.productId) {
          this.$message.error("请选择商品");
          return;
        }
        var tempUrl = "/pages/page_index/goods_details/index?id=" + this.productId;
        this.$emit('handleSumbit', tempUrl);
        return;
      }
      //如果clickID 为5 ,则文章详情
      if (this.clickId === 5) {
        if (!this.productId) {
          this.$message.error("请选择文章");
          return;
        }
        var _tempUrl = "/pages/page_index/news_details/index?id=" + this.productId;
        this.$emit('handleSumbit', _tempUrl);
        return;
      }
      //如果clickID 为6,则自定义链接
      if (this.clickId === 6) {
        if (!this.zdyUrl) {
          this.$message.error("自定义链接不能为空!");
          return;
        }
        this.$emit('handleSumbit', this.zdyUrl);
      }
    },
    handleClose: function handleClose() {
      this.$emit("handClose");
    }
  }
};