import Layout from '@/layout';
var liveRouter = {
  path: '/live',
  component: Layout,
  redirect: '/live/liveManager',
  name: 'live',
  meta: {
    title: '直播管理',
    icon: 'mic'
  },
  children: [{
    path: 'anchor/index',
    component: function component() {
      return import('@/views/live/anchor/list');
    },
    name: 'LiveAnchor',
    meta: {
      title: '主播列表',
      icon: ''
    }
  }, {
    path: 'goods/index',
    component: function component() {
      return import('@/views/live/goods/list');
    },
    name: 'LiveGoods',
    meta: {
      title: '直播商品列表',
      icon: ''
    }
  }, {
    path: 'room/index',
    name: 'LiveRoom',
    component: function component() {
      return import('@/views/live/room/list');
    },
    meta: {
      title: '直播间列表',
      icon: ''
    }
  }, {
    path: 'room/editRoom/:id?/:isDisabled?',
    name: 'LiveRoomEdit',
    component: function component() {
      return import('@/views/live/room/edit');
    },
    meta: {
      title: '添加直播间',
      noCache: true,
      activeMenu: "/live/room/index"
    }
  }]
};
export default liveRouter;