import Layout from '@/layout';
var ticketRouter = {
  path: '/ticket',
  component: Layout,
  redirect: '/ticket/product/index',
  name: 'ticket',
  meta: {
    title: '票务管理',
    icon: 'clipboard'
  },
  children: [{
    path: 'product/index',
    component: function component() {
      return import('@/views/ticket/product/list');
    },
    name: 'TicketProduct',
    meta: {
      title: '产品列表',
      icon: ''
    }
  }, {
    path: 'product/addTicketProduct/:id?/:isDisabled?',
    component: function component() {
      return import('@/views/ticket/product/addTicketProduct/index');
    },
    name: 'TicketProductAdd',
    meta: {
      title: '产品添加',
      noCache: true,
      activeMenu: "/ticket/product/index"
    },
    hidden: true
  }]
};
export default ticketRouter;