var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-input", {
        staticStyle: { width: "80%" },
        attrs: { disabled: "" },
        model: {
          value: _vm.url,
          callback: function ($$v) {
            _vm.url = $$v
          },
          expression: "url",
        },
      }),
      _vm._v(" "),
      _c("el-button", { on: { click: _vm.open } }, [_vm._v("获取链接")]),
      _vm._v(" "),
      _c("dio_link", {
        attrs: { visible: _vm.visible },
        on: { handleSumbit: _vm.handleSumbit, handClose: _vm.handleClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }