import Layout from '@/layout';
var servicesRouter = {
  path: '/services',
  component: Layout,
  redirect: '/services/index',
  name: 'services',
  meta: {
    title: '服务管理',
    icon: 'clipboard'
  },
  children: [{
    path: 'facilities/index',
    component: function component() {
      return import('@/views/services/facilities/list');
    },
    name: 'facilities',
    meta: {
      title: '公告设施',
      icon: ''
    }
  }, {
    path: 'answer/index',
    component: function component() {
      return import('@/views/services/answer/list');
    },
    name: 'answer',
    meta: {
      title: '问答记录',
      icon: ''
    }
  }]
};
export default servicesRouter;