import Layout from '@/layout';
var noticeRouter = {
  path: '/notice',
  component: Layout,
  redirect: '/notice/index',
  name: 'notice',
  meta: {
    title: '官方公告',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/notice/index');
    },
    name: 'NoticeIndex',
    meta: {
      title: '官方公告',
      icon: ''
    }
  }]
};
export default noticeRouter;