import Layout from '@/layout';
var teaEnterpriseRouter = {
  path: '/teaEnterprise',
  component: Layout,
  redirect: '/teaEnterprise/teaEnterpriseManager',
  name: 'teaEnterprise',
  meta: {
    title: '油茶企业',
    icon: 'clipboard'
  },
  children: [{
    path: 'list/list',
    name: 'teaEnterpriseManager',
    component: function component() {
      return import('@/views/teaEnterprise/view/index');
    },
    meta: {
      title: '油茶企业列表',
      icon: 'clipboard'
    }
  }]
};
export default teaEnterpriseRouter;