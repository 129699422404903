//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fileFileApi } from '@/api/systemSetting';
import { getToken } from '@/utils/auth';
export default {
  name: 'UploadFile',
  props: {
    value: {},
    isShow: true
  },
  data: function data() {
    return {
      myHeaders: {
        'X-Token': getToken()
      },
      url: ''
    };
  },
  beforeMount: function beforeMount() {
    if (this.value) {
      this.url = this.value;
    }
  },
  methods: {
    handleChange: function handleChange(file) {
      //获取上传文件大小
      var imgSize = Number(file.size / 1024 / 1024);
      if (imgSize > 50) {
        this.$msgbox({
          title: "",
          message: "文件大小不能超过50MB，请重新上传。",
          type: "warning"
        });
        return false;
      }
      return true;
    },
    // 上传
    handleUploadForm: function handleUploadForm(param) {
      var _this = this;
      var formData = new FormData();
      var data = {
        model: this.$route.path.split('/')[1],
        pid: 10
      };
      formData.append('multipart', param.file);
      var loading = this.$loading({
        lock: true,
        text: '上传中，请稍候...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      fileFileApi(formData, data).then(function (res) {
        loading.close();
        _this.url = res.url;
        _this.$emit('input', _this.url);
        _this.$message.success('上传成功');
      }).catch(function (res) {
        loading.close();
      });
    }
  }
};