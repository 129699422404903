function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _variables from '@/styles/variables.scss';
export default {
  data: function data() {
    return {
      // 顶部栏初始数
      visibleNumber: 10,
      // 当前激活菜单的 index
      topItems: []
    };
  },
  computed: {
    theme: function theme() {
      return this.$store.state.settings.theme;
    },
    // 顶部显示菜单
    topMenus: function topMenus() {
      var topMenus = [];
      this.routers.map(function (menu) {
        if (menu.hidden !== true) {
          // 兼容顶部栏一级菜单内部跳转
          if (menu.path === "/") {
            topMenus.push(menu.children[0]);
          } else {
            topMenus.push(menu);
          }
        }
      });
      return topMenus;
    },
    // 所有的路由信息
    routers: function routers() {
      return this.$store.state.permission.topbarRouters;
    },
    // 设置子路由
    childrenMenus: function childrenMenus() {
      return this.routers;
    },
    // 默认激活的菜单
    activeMenu: function activeMenu() {
      var path = this.$route.path;
      if (path !== '/') {
        var paths = path.split("/").map(function (el) {
          return el.trim();
        }).filter(function (item) {
          return item.trim() !== '';
        });
        if (paths && paths.length > 1) {
          path = '/' + paths[0];
        }
      }
      this.activeRoutes(path, false);
      return path;
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener('resize', this.setVisibleNumber);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('resize', this.setVisibleNumber);
  },
  mounted: function mounted() {
    this.setVisibleNumber();
  },
  methods: {
    // 根据宽度计算设置显示栏数
    setVisibleNumber: function setVisibleNumber() {
      var width = document.body.getBoundingClientRect().width / 3;
      this.visibleNumber = parseInt(width / 85);
    },
    // 菜单选择事件
    handleSelect: function handleSelect(key, keyPath) {
      this.currentIndex = key;
      this.activeRoutes(key, true);
      this.$store.dispatch('app/toggleSideBarHide', false);
    },
    // 当前激活的路由
    activeRoutes: function activeRoutes(key, isClick) {
      var routes = [];
      if (this.childrenMenus && this.childrenMenus.length > 0) {
        var _iterator = _createForOfIteratorHelper(this.childrenMenus),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var item = _step.value;
            if (key === item.url) {
              if (item.child && item.child.length > 0) {
                routes = item.child;
              } else {
                routes.push(item.child);
              }
              break;
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
      if (routes.length > 0) {
        this.$store.dispatch("permission/clickRoutes", routes);
        var temp = routes[0];
        if (isClick === true) {
          if (!temp.child || temp.child.length === 0) {
            this.$router.push(routes[0].url);
          }
        }
      }
    },
    variables: function variables() {
      return _variables;
    },
    ishttp: function ishttp(url) {
      return url.indexOf('http://') !== -1 || url.indexOf('https://') !== -1;
    }
  }
};