import Layout from '@/layout';
var villageRouter = {
  path: '/village',
  component: Layout,
  redirect: '/village/affairsManager',
  name: 'village',
  meta: {
    title: '村务',
    icon: 'clipboard'
  },
  children: [{
    path: 'organize/list',
    name: 'organizeList',
    component: function component() {
      return import('@/views/village/organize/index');
    },
    meta: {
      title: '村子列表',
      icon: 'clipboard'
    }
  }, {
    path: 'organize/addOrganize/:id?/:isDisabled?',
    component: function component() {
      return import('@/views/village/organize/addOrganize/index');
    },
    name: 'shopProductAdd',
    meta: {
      title: '村子添加',
      noCache: true,
      activeMenu: "/village/organize/list"
    },
    hidden: true
  }, {
    path: 'house/list',
    name: 'houseManager',
    component: function component() {
      return import('@/views/village/house/index');
    },
    meta: {
      title: '村户信息',
      icon: 'clipboard'
    }
  }, {
    path: 'aborigines/list',
    name: 'aboriginesManager',
    component: function component() {
      return import('@/views/village/aborigines/list');
    },
    meta: {
      title: '原住民认证',
      icon: 'clipboard'
    }
  }, {
    path: 'mailbox/list',
    name: 'mailboxManager',
    component: function component() {
      return import('@/views/village/mailbox/index');
    },
    meta: {
      title: '献言建策',
      icon: 'clipboard'
    }
  }, {
    path: 'affairs/list',
    name: 'affairsList',
    component: function component() {
      return import('@/views/village/affairs/index');
    },
    meta: {
      title: '阳光村务列表',
      icon: 'clipboard'
    }
  }, {
    path: 'place/index',
    name: 'placeList',
    component: function component() {
      return import('@/views/village/place/list');
    },
    meta: {
      title: '场所列表',
      icon: 'clipboard'
    }
  }, {
    path: 'place/log/index',
    name: 'placeLogList',
    component: function component() {
      return import('@/views/village/placeLog/index');
    },
    meta: {
      title: '预约记录',
      icon: 'clipboard'
    }
  }]
};
export default villageRouter;