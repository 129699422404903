//
//
//
//
//
//
//
//

import dio_link from '@/components/jumpLink/forGenrator/link.vue';
export default {
  name: 'JumpLink',
  components: {
    dio_link: dio_link
  },
  props: {
    value: {}
  },
  data: function data() {
    return {
      visible: false,
      url: null
    };
  },
  beforeMount: function beforeMount() {
    this.url = this.value;
  },
  methods: {
    open: function open() {
      this.visible = true;
    },
    handleClose: function handleClose() {
      this.visible = false;
    },
    handleSumbit: function handleSumbit(url) {
      this.visible = false;
      console.log(url);
      this.url = url;
      this.$emit('input', url);
    }
  }
};