import Layout from '@/layout';
var teaProductRouter = {
  path: '/teaProduct',
  component: Layout,
  redirect: '/teaProduct/TeaProductManager',
  name: 'TeaProduct',
  meta: {
    title: '油茶产品',
    icon: 'clipboard'
  },
  children: [{
    path: 'cate/list',
    name: 'TeaProductCateManager',
    component: function component() {
      return import('@/views/teaproduct/cate/index');
    },
    meta: {
      title: '油茶产品分类',
      icon: 'clipboard'
    }
  }, {
    path: 'list/list',
    name: 'TeaProductManager',
    component: function component() {
      return import('@/views/teaproduct/view/index');
    },
    meta: {
      title: '油茶产品列表',
      icon: 'clipboard'
    }
  }]
};
export default teaProductRouter;