import Layout from '@/layout';
var contentRouter = {
  path: '/guide',
  component: Layout,
  redirect: '/guide/guideManager',
  name: 'guide',
  meta: {
    title: '地图导览',
    icon: 'map-location'
  },
  children: [{
    path: 'scenicManager',
    name: 'scenicManager',
    component: function component() {
      return import('@/views/guide/scenic/list');
    },
    meta: {
      title: '景区管理',
      icon: 'clipboard'
    }
  }, {
    path: 'scenicCreat/:id?',
    name: 'scenicCreat',
    component: function component() {
      return import('@/views/guide/scenic/edit');
    },
    meta: {
      title: '添加景区',
      noCache: true,
      activeMenu: "/guide/scenicManager"
    }
  }, {
    path: 'categoryManager',
    name: 'categoryManager',
    component: function component() {
      return import('@/views/guide/category/list');
    },
    meta: {
      title: '景区分类管理',
      icon: 'clipboard'
    }
  }, {
    path: 'categoryCreat/:id?',
    name: 'categoryCreat',
    component: function component() {
      return import('@/views/guide/category/edit');
    },
    meta: {
      title: '添加景区分类',
      noCache: true,
      activeMenu: "/guide/categoryManager"
    }
  }, {
    path: 'spotManager',
    name: 'spotManager',
    component: function component() {
      return import('@/views/guide/spot/list');
    },
    meta: {
      title: '景区景点管理',
      icon: 'clipboard'
    }
  }, {
    path: 'spotCreat/:id?',
    name: 'spotCreat',
    component: function component() {
      return import('@/views/guide/spot/edit');
    },
    meta: {
      title: '添加景区景点',
      noCache: true,
      activeMenu: "/guide/spotManager"
    }
  }, {
    path: 'lineManager',
    name: 'lineManager',
    component: function component() {
      return import('@/views/guide/line/list');
    },
    meta: {
      title: '景区路线管理',
      icon: 'clipboard'
    }
  }, {
    path: 'lineCreat/:id?',
    name: 'lineCreat',
    component: function component() {
      return import('@/views/guide/line/edit');
    },
    meta: {
      title: '添加景区路线',
      noCache: true,
      activeMenu: "/guide/lineManager"
    }
  }, {
    path: 'lineSpotManager/:id/:scenicId',
    name: 'lineSpotManager',
    component: function component() {
      return import('@/views/guide/line/spotList');
    },
    meta: {
      title: '路线景点管理',
      icon: 'clipboard'
    }
  }, {
    path: 'lineSpotCreat/:id?',
    name: 'lineSpotCreat',
    component: function component() {
      return import('@/views/guide/line/spotEdit');
    },
    meta: {
      title: '添加路线景点',
      noCache: true,
      activeMenu: "/guide/lineSpotManager"
    }
  }, {
    path: 'labelSpotManager',
    name: 'labelSpotManager',
    component: function component() {
      return import('@/views/guide/label/list');
    },
    meta: {
      title: '景点标签管理',
      icon: 'clipboard'
    }
  }, {
    path: 'labelSpotCreat/:id?',
    name: 'labelSpotCreat',
    component: function component() {
      return import('@/views/guide/label/edit');
    },
    meta: {
      title: '添加景点标签',
      noCache: true,
      activeMenu: "/guide/labelSpotManager"
    }
  }]
};
export default contentRouter;