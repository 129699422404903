import Layout from '@/layout';
var distributionRouter = {
  path: '/distribution',
  component: Layout,
  redirect: '/distribution/index',
  name: 'Distribution',
  meta: {
    title: '分销',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/distribution/index');
    },
    name: 'distributionIndex',
    meta: {
      title: '分销员管理',
      icon: ''
    }
  }, {
    path: 'distributionconfig',
    component: function component() {
      return import('@/views/distribution/config/index');
    },
    name: 'distributionConfig',
    meta: {
      title: '分销配置',
      icon: ''
    }
  }, {
    path: 'disLevelConfig',
    component: function component() {
      return import('@/views/distribution/level/list');
    },
    name: 'disLevelConfig',
    meta: {
      title: '分销等级配置',
      icon: ''
    }
  }, {
    path: 'disLevelConfigCreat/:id?',
    name: 'disLevelConfigCreat',
    component: function component() {
      return import('@/views/distribution/level/edit');
    },
    meta: {
      title: '添加分销等级',
      noCache: true,
      activeMenu: "/distribution/disLevelConfig"
    }
  }, {
    path: 'distAllocationConfig',
    component: function component() {
      return import('@/views/distribution/allocation/list');
    },
    name: 'distAllocationConfig',
    meta: {
      title: '分销佣金比例配置',
      icon: ''
    }
  }, {
    path: 'disAllocationConfigCreat/:id?',
    name: 'disAllocationConfigCreat',
    component: function component() {
      return import('@/views/distribution/allocation/edit');
    },
    meta: {
      title: '添加分销比例',
      noCache: true,
      activeMenu: "/distribution/distAllocationConfig"
    }
  }]
};
export default distributionRouter;