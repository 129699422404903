import Layout from '@/layout';
var newsRouter = {
  path: '/news',
  component: Layout,
  redirect: '/news/index',
  name: 'news',
  meta: {
    title: '新闻咨讯',
    icon: 'clipboard'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/news/index');
    },
    name: 'NewsIndex',
    meta: {
      title: '新闻咨讯',
      icon: ''
    }
  }]
};
export default newsRouter;